body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html , body {
    height: 100%;
}

html {
    overflow-y: hidden;
}

#root{
    height: 100%;
}

.App, .modal{
    text-align: center;
    height: 100%;
}

#createUser{
    marginTop: 10px;
}

.dashboard {
    height: 100%;
}

#userTable {
    height: 92% ;
}

.columnsDiv {
    height: 92%;
}

#tagDiv {
    margin-bottom: 0px;
}

.help.is-danger.formLoginErrors{
    font-size: 0.60rem;
}

.columns.homePageColumns {
    margin-bottom: 0px;
}

.tagElement {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
}

#tagButtonColumn {
    float: right;
}

.tagButton {
    float: right;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
}

.reactTableDiv {
    margin-left: 8px;
    margin-right: 8px;
}

#rightButton {
    float: right;
}

#myColum {
    width: 100%;
    flex: 1 1;
    flex-direction: row;
}

#statsColum {
    width: 70%;
}

#createColum {
    width: 30%;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.navbar-dropdown.is-light{
    background-color:whitesmoke ;
}

.App-login-header {
    background-color: #e2e7f3;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.icon.is-medium.is-pulled-left.saveButton{
    margin-bottom: 8px;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.margin-top10 {
    margin-top: 10px;
}

.fullwidth {
    width: 100%;
}

#user-box {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
}

/*#inputs-box {*/
/*    margin-top: 5px;*/
/*    margin-bottom: 10px;*/
/*    margin-right: 10px;*/
/*    height: 91%;*/
/*}*/


/*.button.is-info.is-loading.userFormSaveButton{*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.button.is-info.userFormSaveButton{*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.button.is-danger.userFormSaveButton{*/
/*    margin-bottom: 1rem;*/
/*}*/

#userList {
    display: block;
    max-height: 60vh;
    overflow-y: auto;
}

#userListP {
    max-width:1000vh ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.is-scrollable tbody {
    display: block;
    max-height: 50vh;
    overflow-y: auto;
}

.is-scrollable thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.separate-inputs {
    margin-top: 10px;
}

.is-so-small {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.anchor-button {
    background: none !important;
    color: #0000EE;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    font-size: 12px;
}

.iframe-report {
    width: 100%;
}

.box-under-navigation {
    margin-top: 20px !important;
}

.margin-down-objects {
    margin-bottom: 10px !important;
}

.center-horizontal-objects {
    margin: 0 auto;
}

.inputs-width {
    max-width: 350px;
}

.box-wrap-input {
    max-width: 500px;
}

.subtitle-margin-down {
    margin-bottom: 20px !important;
}

.title-margin-down {
    margin-bottom: 35px !important;
}

.title-margin-down-over-button {
    margin-bottom: 20px !important;
}

.title-margin-up {
    margin-top: 30px;
}


.user-form-modal-content {
    width: 90%;
    margin: 0 !important;
}


.permissionsContainer{
    height: 100%;
    width: 100%;
    margin: 10px 0 0 0;
}

.permissionsTittle{
    width: 100%;
    font-size: 1rem;
    text-align: center;
}

.checkboxes{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.CheckboxContainer{
    margin: 5px;
}

.checkboxInput{
    margin-right: 5px;
}

.companyContainer{
    margin: 10px 0 0 0;
}

.addCompany{
    margin-left: auto;
}

.field .is-horizontal .centered{
    align-items: baseline;
}

.userFormModal{
    padding: 10px !important;
}

.myTitle{
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.mySecondTitle{
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: small;
}

.permissionsList{
    text-align: left;
}

.permissions{
    cursor:pointer;
    text-align: center;
    padding:10px;
    margin-bottom: 10px;
    background-color: gray;
    display: block;
    width: 100%;
    background-color:#f8f8fa;
}

.permissions:hover{
    border-right-width: 4px;
    border-right-color: #3f72af;
    border-right-style: solid;
}

.SecondColumn{
    width: 100%;
}

.leftCheckboxes{
    text-align: left;
    margin: 20px;
}

.leftDropdown{
    text-align: left;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.powerBiLink{
    margin: 10px;
}

.saveButton{
    margin-top: 1.5rem;
}

#myBoxSelected{
    font-weight: bold;
}

#myPermissionSelected{
    font-weight: bold;
    border-right-width: 4px;
    border-right-color: #3f72af;
    border-right-style: solid;
}

#columnCentered{
    margin: auto;
}

.ringContainer{
    margin:auto;
}

.errorContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1050;
    display: flex;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.8);
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
